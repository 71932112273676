import {onDomChanges, onDomReady} from "../../components/dynamic/observer";

class Popup {
  constructor(link) {
    this.target = link;
    this.initTimeoutSetting = 1000;
    this.isCityCet = this.getCookie('k2city');
    this.redirectUrls = {
      'rostov': 'https://rostov-na-donu.k2avto.ru/',
      'stav': 'https://k2avto.ru'
    }
    this.bind();
  }

  bind() {
    if (!this.isCityCet && this.initTimeoutSetting !== 0) {
      this.initByTimeout();
    }

    this.initButtons();
    onDomChanges(() => {
      this.initButtons();
    });
  }

  initButtons() {
    const cityButtons = document.querySelectorAll('[data-city-button]')
    cityButtons.forEach((button) => {
      button.addEventListener('click', (e) => {
        const link = e.target;
        this.setCookie('k2city', link.dataset.cityKey, 365, '.k2avto.ru')
        if (link.dataset.cityUrl !== window.location) {
          window.location.href = link.dataset.cityUrl;
        }
      })
    })
  }

  initByTimeout() {
    setTimeout(() => {
      this.openPopup();
    }, this.initTimeoutSetting);
  }

  openPopup() {
    if (this.target && !document.querySelector('body').classList.contains('modal-opened')) {
      this.target.click();
    }
  }

  setCookie(name, value, days, domain) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/; domain=" + domain + "; Secure";
  }

  getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
}
onDomReady(() => {
  const link = document.querySelector('[data-city-popup-link]');
  if (!link) {
    return
  }
  new Popup(link);
})